import * as React from "react"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "utils/linkResolver"
import {
  redSection,
  jobTextWrapper,
  jobText,
  job,
  noJobs,
} from "./styles.module.css"
import { Helmet } from "react-helmet"
import { AnFadeIn, Button, ButtonLink } from "components"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import { navigate } from "gatsby-link"

const ContactPage = ({ data }) => {
  const jobs = data.allPrismicJob.nodes
  return (
    <AnFadeIn>
      <section>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`Still Curious | Work with us`}</title>
        </Helmet>
        <section className={redSection}>
          <section className={job}>
            <h1>Work with us</h1>

            {jobs.length <= 0 && (
              <div className={noJobs}>
                <h2>We have no vacancies at this moment.</h2>
                <Button
                  navy
                  title="Contact Us"
                  onClick={() => navigate("/contact")}
                />
              </div>
            )}

            {jobs.map(({ id, data }) => {
              const {
                application_link,
                job_description_left,
                job_description_right,
                job_title,
              } = data

              return (
                <section key={id} className={jobText}>
                  <h2>{job_title.text}</h2>
                  <div className={jobTextWrapper}>
                    <div>
                      <RichText render={job_description_left.raw} />
                    </div>
                    <div>
                      <RichText render={job_description_right.raw} />
                    </div>
                  </div>
                  <div className={jobTextWrapper}>
                    <div />
                    <ButtonLink
                      navy
                      title={"DOWNLOAD JOB APPLICATION HERE"}
                      target="blank"
                      to={application_link.url}
                    />
                  </div>
                </section>
              )
            })}
          </section>
        </section>
      </section>
    </AnFadeIn>
  )
}

export const query = graphql`
  query MyQuery {
    allPrismicJob(filter: { data: { active: { eq: true } } }) {
      nodes {
        data {
          application_link {
            url
          }
          job_description_left {
            raw
          }
          job_description_right {
            raw
          }
          job_title {
            text
          }
        }
        id
      }
    }
  }
`

export default withPrismicPreview(ContactPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
